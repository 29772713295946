import { toRedirect } from './toRedirect';

import { providerUtils } from '../../paymentMethodsProviders/PaymentProviderComponents';

import { storage } from '../storage';

import { ACTIONS, STORAGE_3DS_DATA } from '../../constants/paymentMethodsHub';

const createFromActionMock = jest.fn();

jest.mock('../../paymentMethodsProviders/PaymentProviderComponents', () => ({
  providerUtils: jest.fn().mockResolvedValue({}),
}));

providerUtils.mockImplementation(() => ({
  createFromAction: createFromActionMock,
}));

const response = {
  providerStatusRaw: 'RedirectShopper',
  action: '{"type": "redirect"}',
  paymentMethodUuid: 'uuid',
  paymentData: 'paymentData',
  reference: 'reference',
  type: 'card',
};

const composablePayload = {
  contextData: {
    isRedirected: false,
    flowTracking: {
      currentAction: ACTIONS.add,
      addIsEmbedded: false,
    },
  },
  commonEmit: jest.fn(),
  originalResponse: response,
  parentActionCallback: jest.fn(),
  setupProps: {
    props: {
      provider: 'Test/3.13.0',
    },
  },
};

describe('When the "toRedirect" method is called', () => {
  describe('And the "response" is "redirectShopper" and the responseAction is "Redirect"', () => {
    it('Then the providerUtils has been called', () => {
      toRedirect(composablePayload);

      expect(providerUtils).toHaveBeenCalledWith('Test/3.13.0');
    });

    it('Then the payment data is stored in sessionStore', () => {
      expect(storage.get(STORAGE_3DS_DATA)).toBeTruthy();
    });

    it('Then the "createFromAction" method has been called', () => {
      expect(createFromActionMock).toHaveBeenCalled();
    });
  });
});
